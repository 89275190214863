import {
    AnalyticsClient,
    AnalyticsConfigType,
    AnalyticsEventParamsType
} from '@lib/analytics/AnalyticsClient';
import { useFirebaseApp } from '@lib/firebase';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef } from 'react';

let analyticsClient: AnalyticsClient;

type AnalyticsContextType = {
    analytics: AnalyticsClient;
};

const AnalyticsContext = React.createContext<null | AnalyticsContextType>(null);
AnalyticsContext.displayName = 'AnalyticsContext';

export const getPathInfo = (path: string) => {
    path = path.replace(/^\/(de|en|fr|fr-ch|en-gb|de-ch|de-at)\//i, '/');
    const pathAndSearch = path.split('?');
    const pathname = pathAndSearch[0];
    const search = pathAndSearch.length > 1 ? pathAndSearch[1] : '';

    const paramsSplit = search ? search.split('=') : [];
    const params: any = {};

    if (paramsSplit.length) {
        paramsSplit.forEach((value, index) => {
            if (index % 2 == 0) {
                params[value] = paramsSplit[index + 1] || '';
            }
        });
    }

    const pathname_parts = pathname.replace(/^\//, '').replace(/\/$/, '').split('/');

    return {
        pathname,
        pathname_parts,
        search,
        params
    };
};

export const getAnalyticsInfoFromPath = (path: string) => {
    const path_info = getPathInfo(path);
    const event_id = path_info.pathname_parts[1] || '';
    const page = path_info.pathname_parts[2] || '';
    const subpage = path_info.pathname_parts[3] || '';

    if (!page) {
        return null;
    }

    const analytics_params: any = {
        event_id: event_id
    };

    if (path_info.params) {
        Object.keys(path_info.params).forEach((key) => {
            if (['program_id', 'schedule_id', 'event_schedule_id'].includes(key)) {
                analytics_params.event_schedule_id = path_info.params[key];
            } else if (key === 'sid' && page === 'exhibition') {
                analytics_params.cpd1 = 'sponsoring_id';
                analytics_params.cpv1 = path_info.params[key];
            }
        });
    }

    return {
        path_info,
        page,
        subpage,
        event_id,
        analytics_params
    };
};

export const AnalyticsProvider: React.FunctionComponent<{
    analyticsConfig: AnalyticsConfigType;
}> = ({ analyticsConfig, ...props }) => {
    const { app, userClaims, userData } = useFirebaseApp();

    if (typeof window !== 'undefined' && app && !analyticsClient) {
        analyticsClient = new AnalyticsClient(analyticsConfig, app, userClaims, userData);
    }

    useEffect(() => {
        if (analyticsClient) {
            analyticsClient.setUserClaims(userClaims);
            analyticsClient.setUserData(userData);
        }
    }, [userClaims, userData]);

    const handlePageView = useCallback((e: CustomEvent) => {
        const path: string = e.detail.path || '';

        if (!path.startsWith('/events/') || path.indexOf('signInToken') !== -1) {
            return;
        }

        const analytics_info_from_path = getAnalyticsInfoFromPath(path);

        if (!analytics_info_from_path) {
            return;
        }

        const params: AnalyticsEventParamsType = {
            _action: 'page_view',
            _category: analytics_info_from_path.page,
            event_id: analytics_info_from_path.event_id
        };

        if (analytics_info_from_path.page == 'on-demand') {
            params._category = 'ondemand';
        }

        if (analytics_info_from_path.subpage) {
            params._category += '_' + analytics_info_from_path.subpage;
        }

        if (analyticsClient) {
            analyticsClient.send('app_page_view', {
                ...params,
                ...analytics_info_from_path.analytics_params
            });
        }
    }, []);

    useEffect(() => {
        if (document) {
            document.addEventListener('app_page_view', handlePageView as EventListener);
        }

        return () => {
            document.removeEventListener('app_page_view', handlePageView as EventListener);
        };
    }, [handlePageView]);

    return <AnalyticsContext.Provider value={{ analytics: analyticsClient }} {...props} />;
};

export const useAnalytics = (): AnalyticsContextType => {
    const context = React.useContext(AnalyticsContext);
    if (!context) {
        throw new Error(`useAnalytics must be used within a AnalyticsProvider`);
    }
    return context;
};

export const useWatchTimeAnalytics: (
    params: AnalyticsEventParamsType
) => (action: 'start' | 'stop' | 'progress') => void = (params: AnalyticsEventParamsType) => {
    const { analytics } = useAnalytics();

    const callBackFunc = useCallback(
        (params: AnalyticsEventParamsType) => {
            // Date.getTime() ms or a numeric value of 0, 1, 2 or 3
            // 0 - watch session got stopped
            // 1 - a stopped watch session got one 'progress' call
            // 2 - a stopped watch session got two successive 'progress' calls
            // 3 - a stopped watch session got three successive 'progress' calls
            const watchSessionStartTimeMs = useRef(0);

            return (action: 'start' | 'stop' | 'progress') => {
                if (typeof analytics == 'undefined') {
                    return;
                }

                const currentWatchSessionIsStopped =
                    !watchSessionStartTimeMs.current || watchSessionStartTimeMs.current < 5;

                // Calculate watched seconds based on the time difference to watch session start time
                const watchedSeconds = currentWatchSessionIsStopped
                    ? 0
                    : Math.round((new Date().getTime() - watchSessionStartTimeMs.current) / 1000);

                if (analyticsClient.debuggingEnabled()) {
                    console.log('analytics > watch time >', { action, watchedSeconds, params });
                }

                // (Re)start watch session, accounting for the possibility that a running session might
                // get a 'start' call
                if (action === 'start') {
                    watchSessionStartTimeMs.current =
                        new Date().getTime() -
                        Math.min(watchedSeconds, analytics.getWatchTimeIntervalSeconds());
                    if (analyticsClient.debuggingEnabled()) {
                        console.log(
                            'analytics > watch time > session reset to',
                            watchSessionStartTimeMs.current
                        );
                    }
                    return;
                }

                // If the watch session got stopped recently and didn't get restarted
                if (currentWatchSessionIsStopped) {
                    if (analyticsClient.debuggingEnabled()) {
                        console.log('analytics > watch time > session is stopped');
                    }
                    // And we are getting progress events
                    if (action === 'progress') {
                        watchSessionStartTimeMs.current++;
                        if (analyticsClient.debuggingEnabled()) {
                            console.log(
                                'analytics > watch time > session is stopped + progress',
                                watchSessionStartTimeMs.current
                            );
                        }
                    }
                    // After 3 such progress events, restart the watch session
                    if (watchSessionStartTimeMs.current >= 3) {
                        // Also account for ~3 seconds watch time
                        watchSessionStartTimeMs.current = new Date().getTime() - 3000;
                        if (analyticsClient.debuggingEnabled()) {
                            console.log(
                                'analytics > watch time > session is stopped + progress => restart session',
                                watchSessionStartTimeMs.current
                            );
                        }
                    }
                    return;
                }

                if (
                    watchedSeconds >= analyticsClient.getWatchTimeIntervalSeconds() ||
                    (action === 'stop' && watchedSeconds >= 5)
                ) {
                    // Update watch session
                    watchSessionStartTimeMs.current += watchedSeconds * 1000;

                    if (analyticsClient.debuggingEnabled()) {
                        console.log(
                            'analytics > watch time > send watched seconds',
                            watchedSeconds
                        );
                    }

                    analytics.send('player_watch_time', {
                        ...params,
                        _value: watchedSeconds
                    });
                }

                // When the watch session gets stopped, we reset the start time to 0.
                if (action === 'stop') {
                    watchSessionStartTimeMs.current = 0;
                }
            };
        },
        [params]
    );

    return callBackFunc(params);
};

export const useSlateAnalytics = () => {
    const { analytics } = useAnalytics();
    const router = useRouter();

    const analytics_info_from_path = getAnalyticsInfoFromPath(router.asPath);

    return {
        analytics,
        analytics_info_from_path,
        sendAnalytics: (event_name: string, params: AnalyticsEventParamsType) => {
            let category = 'slate_editor';

            if (analytics_info_from_path?.page) {
                let event_name_prefix = analytics_info_from_path?.page;

                if (analytics_info_from_path?.subpage) {
                    event_name_prefix += '_' + analytics_info_from_path.subpage;
                }
                category = event_name_prefix.replace('on-demand', 'ondemand');
                event_name = event_name_prefix + '_' + event_name;
            }

            analytics.send(event_name, {
                ...params,
                _category: category,
                ...analytics_info_from_path?.analytics_params
            });
        }
    };
};
