declare global {
    interface Window {
        debug_analytics: any;
    }
}

// eslint-disable-next-line simple-import-sort/sort
import { Agent, GetResult } from '@fingerprintjs/fingerprintjs';
import { firebase, IUserDocument } from '@lib/firebase';
import { v4 as uuidv4 } from 'uuid';
import cookie from 'cookie';

export type AnalyticsConfigType = {
    debug: boolean;
    destination: string;
    disabled: boolean;
    watch_time_interval_seconds: number;
};

export type AnalyticsEventParamsType = {
    _category: string;
    _action: string;
    _label?: string;
    _value?: any;
    account_type?: string;
    participant_type?: string;
    event_id?: string;
    event_schedule_id?: string;
    cpd1?: string;
    cpv1?: string;
    cpd2?: string;
    cpv2?: string;
    cpd3?: string;
    cpv3?: string;
    cpd4?: string;
    cpv4?: string;
    cpd5?: string;
    cpv5?: string;
    param1?: string;
    param2?: string;
    param3?: string;
    param4?: string;
};

export type AnalyticsEventExtraParams = {
    user_id: string | undefined;
    user_role?: string;
    page_host: string;
    page_path: string;
    device_id: string;
    device_session_id: string;
    device_user_agent: string;
    device_vendor: string;
    timestamp: firebase.firestore.FieldValue;
};

const SESSION_ID_KEY = 'ELM-A7S-SESSION-ID';

export class AnalyticsClient {
    config: AnalyticsConfigType;
    app: firebase.app.App;
    fingerprint: GetResult | null;
    sessionId: string;
    userClaims: firebase.auth.IdTokenResult['claims'] | null;
    userData: IUserDocument | undefined;

    constructor(
        config: AnalyticsConfigType,
        app: firebase.app.App,
        userClaims: firebase.auth.IdTokenResult['claims'] | null,
        userData: IUserDocument | undefined
    ) {
        this.config = config;
        this.app = app;
        this.fingerprint = null;
        this.sessionId = '';
        this.userClaims = userClaims;
        this.userData = userData;
        this.initSession();
        this.initFingerprintJs();
    }

    initSession() {
        if (window.sessionStorage) {
            try {
                this.sessionId = window.sessionStorage.getItem(SESSION_ID_KEY) || '';

                if (!this.sessionId) {
                    this.sessionId = uuidv4();
                    window.sessionStorage.setItem(SESSION_ID_KEY, this.sessionId);
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            this.sessionId = uuidv4();
        }
    }

    initFingerprintJs() {
        try {
            import('@fingerprintjs/fingerprintjs').then(async (FingerprintJs) => {
                const fingerprintAgent: Agent = await FingerprintJs.load();
                this.fingerprint = await fingerprintAgent.get();
            });
        } catch (err) {
            console.error(err);
        }
    }

    setUserClaims(claims: firebase.auth.IdTokenResult['claims'] | null) {
        this.userClaims = claims;
    }

    setUserData(userData: IUserDocument | undefined) {
        this.userData = userData;
    }

    debuggingEnabled(): boolean {
        return this.config.debug || window.debug_analytics;
    }

    getDestination(): string {
        return this.config.destination;
    }

    getWatchTimeIntervalSeconds(): number {
        return this.config.watch_time_interval_seconds;
    }

    getUserId(): string {
        return this.app.auth().currentUser?.uid || 'anonymous';
    }

    getExtraParams(): AnalyticsEventExtraParams {
        const user_id = this.getUserId();
        const fingerprint_id = this.fingerprint?.visitorId || this.sessionId;

        return {
            user_id: user_id,
            page_host: location?.host || '',
            page_path: location?.pathname || '',
            device_id: `${fingerprint_id}|${user_id}`,
            device_session_id: `${this.sessionId}|${user_id}`,
            device_user_agent: navigator?.userAgent || '',
            device_vendor: navigator?.vendor || '',
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
        };
    }

    getCookieValue(cookie_name: string): string {
        try {
            const cookies = cookie.parse(document.cookie || '');
            return cookie_name in cookies ? cookies[cookie_name] : '';
        } catch (err) {
            console.error(err);
        }

        return '';
    }

    send(event_name: string, params: AnalyticsEventParamsType) {
        if (this.config.disabled) {
            return;
        }
        try {
            if (this.userData?.account_type) {
                params.account_type = this.userData.account_type;
            }

            const data = {
                _event_name: event_name.replace('on-demand', 'ondemand').replace('-', '_'),
                ...params,
                ...this.getExtraParams()
            };

            if (data?.account_type === 'access_link') {
                data.param1 = this.userClaims?.access_link_id || '';
                data.param2 = this.userClaims?.access_link_description || '';
                data.param3 = this.getCookieValue('access_link_reference') || '';
            }

            if (this.debuggingEnabled()) {
                console.log('analytics > sending data', data);
            }

            this.app.firestore().collection(this.getDestination()).add(data);
        } catch (err) {
            console.error(err);
        }
    }
}
